<template>
  <v-app>
    <v-container>
      <h1>Danh sách bài học</h1>
      <keep-alive>
        <courseCurriculumEditor :course_slug="course_slug" />
      </keep-alive>
    </v-container>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import courseCurriculumEditor from "@/components/course-model/course-one-to-one-curriculum-editor";

export default {
  data() {
    return {
      active_tab: null,
      course_slug: "",
    };
  },
  components: {
    courseCurriculumEditor,
  },
  mounted() {
    this.course_slug = this.$route.query.course_slug;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách bài học" }]);
    // set the tab from previousmounted() {
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
  },
};
</script>
