<template>
  <v-app>
    <draggable v-model="lessonsCourseOneToOne" handle=".handle" v-if="lessonsCourseOneToOne.length > 0">
      <curriculumItem class="curriculum-parent item" v-for="(lesson,lessonIndex) in lessonsCourseOneToOne" :key="lessonIndex"
                      :lesson="lesson"
                      :course_slug="course_slug"
                      @handleChildAction="handleChildAction"
      >
      </curriculumItem>
    </draggable>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import curriculumItem from "@/components/lesson/LessonMenuItemCourse11";
import draggable from 'vuedraggable'

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {
  GET_LESSON,
  DELETE_LESSON
} from "@/store/lessonCourseOneToOne.module";

export default {
  props: ["course_slug"],
  components: {
    curriculumItem,
    draggable
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Giáo trình"}]);
    await this.$store.dispatch(GET_LESSON, {course_slug: this.course_slug});
  },
  computed: {
    ...mapGetters({
      lessonsCourseOneToOne: "lessonsCourseOneToOne",
    }),
  },
  methods: {
    handleChildAction(payload) {
      // view lesson detail
      let lesson = payload.item;
      if (payload.action === 1) {
        this.$router.push({
          path:'/course-one-to-one/lesson-detail',
          query:{
            lesson_slug : lesson.slug
          }
        })
      }
      if (payload.action === 5) {
        confirm("Are you sure you want to delete this item?") &&
        this.$store
            .dispatch(DELETE_LESSON, {
              lesson_id: lesson.id,
              course_slug: this.course_slug,
            }).then((data) => {
          if (data.status) {
            this.$store.dispatch(GET_LESSON, {course_slug: this.course_slug});
            this.$toasted.success('Xóa bài học thành công', {
              position: "top-right",
              duration: 3000
            })
          }
          else {
            this.$toasted.error('Xóa bài học thất bại', {
              position: "top-right",
              duration: 3000
            })
          }
        });
      }
    },
  }
  ,
}
;
</script>

<style lang="scss">
.v-list-item__icon {
  display: none !important;
}

.add-lesson {
  height: 40px !important;
}

.add-button-container {
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
  }
}

.curriculum-parent {
  margin-bottom: 20px;
}
</style>
